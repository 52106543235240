


/*!* ===== MENU ===== *!*/
/*.menu {*/
/*    float: left;*/
/*    height: 700px;;*/
/*    width: 70px;*/
/*    background: #4768b5;*/
/*    background: -webkit-linear-gradient(#4768b5, #35488e);*/
/*    background: -o-linear-gradient(#4768b5, #35488e);*/
/*    background: -moz-linear-gradient(#4768b5, #35488e);*/
/*    background: linear-gradient(#4768b5, #35488e);*/
/*    box-shadow: 0 10px 20px rgba(0,0,0,0.19);*/
/*}*/

/*.menu .items {*/
/*    list-style:none;*/
/*    margin: auto;*/
/*    padding: 0;*/
/*}*/

/*.menu .items .item {*/
/*    height: 70px;*/
/*    border-bottom: 1px solid #6780cc;*/
/*    display:flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    color: #9fb5ef;*/
/*    font-size: 17pt;*/
/*}*/

/*.menu .items .item-active {*/
/*    background-color:#5172c3;*/
/*    color: #FFF;*/
/*}*/

/*.menu .items .item:hover {*/
/*    cursor: pointer;*/
/*    background-color: #4f6ebd;*/
/*    color: #cfe5ff;*/
/*}*/

/*!* === CONVERSATIONS === *!*/

/*.discussions {*/
/*    width: 35%;*/
/*    height: 700px;*/
/*    box-shadow: 0px 8px 10px rgba(0,0,0,0.20);*/
/*    overflow: hidden;*/
/*    background-color: #87a3ec;*/
/*    display: inline-block;*/
/*}*/

/*.discussions .discussion {*/
/*    width: 100%;*/
/*    height: 90px;*/
/*    background-color: #FAFAFA;*/
/*    border-bottom: solid 1px #E0E0E0;*/
/*    display:flex;*/
/*    align-items: center;*/
/*    cursor: pointer;*/
/*}*/

/*.discussions .search {*/
/*    display:flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    color: #E0E0E0;*/
/*}*/

/*.discussions .search .searchbar {*/
/*    height: 40px;*/
/*    background-color: #FFF;*/
/*    width: 70%;*/
/*    padding: 0 20px;*/
/*    border-radius: 50px;*/
/*    border: 1px solid #EEEEEE;*/
/*    display:flex;*/
/*    align-items: center;*/
/*    cursor: pointer;*/
/*}*/

/*.discussions .search .searchbar input {*/
/*    margin-left: 15px;*/
/*    height:38px;*/
/*    width:100%;*/
/*    border:none;*/
/*    font-family: 'Montserrat', sans-serif;;*/
/*}*/

/*.discussions .search .searchbar *::-webkit-input-placeholder {*/
/*    color: #E0E0E0;*/
/*}*/
/*.discussions .search .searchbar input *:-moz-placeholder {*/
/*    color: #E0E0E0;*/
/*}*/
/*.discussions .search .searchbar input *::-moz-placeholder {*/
/*    color: #E0E0E0;*/
/*}*/
/*.discussions .search .searchbar input *:-ms-input-placeholder {*/
/*    color: #E0E0E0;*/
/*}*/

/*.discussions .message-active {*/
/*    width: 98.5%;*/
/*    height: 90px;*/
/*    background-color: #FFF;*/
/*    border-bottom: solid 1px #E0E0E0;*/
/*}*/

/*.discussions .discussion .photo {*/
/*    margin-left:20px;*/
/*    display: block;*/
/*    width: 45px;*/
/*    height: 45px;*/
/*    background: #E6E7ED;*/
/*    -moz-border-radius: 50px;*/
/*    -webkit-border-radius: 50px;*/
/*    background-position: center;*/
/*    background-size: cover;*/
/*    background-repeat: no-repeat;*/
/*}*/

/*.online {*/
/*    position: relative;*/
/*    top: 30px;*/
/*    left: 35px;*/
/*    width: 13px;*/
/*    height: 13px;*/
/*    background-color: #8BC34A;*/
/*    border-radius: 13px;*/
/*    border: 3px solid #FAFAFA;*/
/*}*/

/*.desc-contact {*/
/*    height: 43px;*/
/*    width:50%;*/
/*    white-space: nowrap;*/
/*    overflow: hidden;*/
/*    text-overflow: ellipsis;*/
/*}*/

/*.discussions .discussion .name {*/
/*    margin: 0 0 0 20px;*/
/*    font-family:'Montserrat', sans-serif;*/
/*    font-size: 11pt;*/
/*    color:#515151;*/
/*}*/

/*.discussions .discussion .message {*/
/*    margin: 6px 0 0 20px;*/
/*    font-family:'Montserrat', sans-serif;*/
/*    font-size: 9pt;*/
/*    color:#515151;*/
/*}*/

/*.timer {*/
/*    margin-left: 15%;*/
/*    font-family:'Open Sans', sans-serif;*/
/*    font-size: 11px;*/
/*    padding: 3px 8px;*/
/*    color: #BBB;*/
/*    background-color: #FFF;*/
/*    border: 1px solid #E5E5E5;*/
/*    border-radius: 15px;*/
/*}*/

/*.chat {*/
/*    width: calc(65% - 85px);*/
/*}*/

/*.header-chat {*/
/*    background-color: #FFF;*/
/*    height: 90px;*/
/*    box-shadow: 0px 3px 2px rgba(0,0,0,0.100);*/
/*    display:flex;*/
/*    align-items: center;*/
/*}*/

/*.chat .header-chat .icon {*/
/*    margin-left: 30px;*/
/*    color:#515151;*/
/*    font-size: 14pt;*/
/*}*/

/*.chat .header-chat .name {*/
/*    margin: 0 0 0 20px;*/
/*    text-transform: uppercase;*/
/*    font-family:'Montserrat', sans-serif;*/
/*    font-size: 13pt;*/
/*    color:#515151;*/
/*}*/

/*.chat .header-chat .right {*/
/*    position: absolute;*/
/*    right: 40px;*/
/*}*/

/*.chat .messages-chat {*/
/*    padding: 25px 35px;*/
/*}*/

/*.chat .messages-chat .message {*/
/*    display:flex;*/
/*    align-items: center;*/
/*    margin-bottom: 8px;*/
/*}*/

/*.chat .messages-chat .message .photo {*/
/*    display: block;*/
/*    width: 45px;*/
/*    height: 45px;*/
/*    background: #E6E7ED;*/
/*    -moz-border-radius: 50px;*/
/*    -webkit-border-radius: 50px;*/
/*    background-position: center;*/
/*    background-size: cover;*/
/*    background-repeat: no-repeat;*/
/*}*/

/*.chat .messages-chat .text {*/
/*    margin: 0 35px;*/
/*    background-color: #f6f6f6;*/
/*    padding: 15px;*/
/*    border-radius: 12px;*/
/*}*/

/*.text-only {*/
/*    margin-left: 45px;*/
/*}*/

/*.time {*/
/*    font-size: 10px;*/
/*    color:lightgrey;*/
/*    margin-bottom:10px;*/
/*    margin-left: 85px;*/
/*}*/

/*.response-time {*/
/*    float: right;*/
/*    margin-right: 40px !important;*/
/*}*/

/*.response {*/
/*    float: right;*/
/*    margin-right: 0px !important;*/
/*    margin-left:auto; !* flexbox alignment rule *!*/
/*}*/

/*.response .text {*/
/*    background-color: #e3effd !important;*/
/*}*/

/*.footer-chat {*/
/*    width: calc(65% - 66px);*/
/*    height: 80px;*/
/*    display:flex;*/
/*    align-items: center;*/
/*    position:absolute;*/
/*    bottom: 0;*/
/*    background-color: transparent;*/
/*    border-top: 2px solid #EEE;*/

/*}*/

/*.chat .footer-chat .icon {*/
/*    margin-left: 30px;*/
/*    color:#C0C0C0;*/
/*    font-size: 14pt;*/
/*}*/

/*.chat .footer-chat .send {*/
/*    color:#fff;*/
/*    background-color: #4f6ebd;*/
/*    position: absolute;*/
/*    right: 50px;*/
/*    padding: 12px 12px 12px 12px;*/
/*    border-radius: 50px;*/
/*    font-size: 14pt;*/
/*}*/

/*.chat .footer-chat .name {*/
/*    margin: 0 0 0 20px;*/
/*    text-transform: uppercase;*/
/*    font-family:'Montserrat', sans-serif;*/
/*    font-size: 13pt;*/
/*    color:#515151;*/
/*}*/

/*.chat .footer-chat .right {*/
/*    position: absolute;*/
/*    right: 40px;*/
/*}*/

/*.write-message {*/
/*    border:none !important;*/
/*    width:60%;*/
/*    height: 50px;*/
/*    margin-left: 20px;*/
/*    padding: 10px;*/
/*}*/

/*.footer-chat *::-webkit-input-placeholder {*/
/*    color: #C0C0C0;*/
/*    font-size: 13pt;*/
/*}*/
/*.footer-chat input *:-moz-placeholder {*/
/*    color: #C0C0C0;*/
/*    font-size: 13pt;*/
/*}*/
/*.footer-chat input *::-moz-placeholder {*/
/*    color: #C0C0C0;*/
/*    font-size: 13pt;*/
/*    margin-left:5px;*/
/*}*/
/*.footer-chat input *:-ms-input-placeholder {*/
/*    color: #C0C0C0;*/
/*    font-size: 13pt;*/
/*}*/

/*.clickable {*/
/*    cursor: pointer;*/
/*}*/
/*#form { background: rgba(0, 0, 0, 0.15); padding: 0.25rem;width:70%; position: fixed; bottom: 0; right: 0; display: flex; height: 3rem; box-sizing: border-box; backdrop-filter: blur(10px); }*/
/*#input { border: none; padding: 0 1rem; width:70%; border-radius: 2rem; margin: 0.25rem; }*/
/*#input:focus { outline: none; }*/
/*#form > button { background: #333; border: none; padding: 0 1rem; margin: 0.25rem; border-radius: 3px; outline: none; color: #fff; }*/

/*#messages {*/
/*    list-style-type: none;*/

/*    padding-bottom: 10%;*/
/*    width: 70%;*/
/*    position: absolute;*/
/*    right: 0; }*/
/*#messages > li { padding: 0.5rem 1rem; }*/
/*#messages > li:nth-child(odd) { background: #efefef; }*/
/*.chatList {*/
/*    height:100%;*/
/*    width:30%;*/
/*    border-right:0.5px solid;*/
/*    margin:0;*/
/*    overflow: hidden;*/
/*    scrollbar-width: none;*/
/*    overflow-y: scroll;*/
/*    position: fixed;*/
/*    top:0;*/
/*    left: 0;*/
/*}*/
/*.chatList::-webkit-scrollbar {*/
/*    display: none;*/
/*}*/
/*.chatList button {*/
/*    width:100%;*/
/*    padding: 5px;*/
/*    margin:0;*/
/*    border-bottom:0.5px solid grey;*/
/*    background-color: white;*/
/*    color:black ;*/
/*    transition:1s;*/
/*}*/
/*.chatList button:hover {*/
/*    background-color: black;*/
/*    color:white ;*/
/*}*/
/*#room {*/
/*   display: block;*/
/*}*/
/*.msgInside {*/
/*    background-color:lightgreen;*/
/*    padding:15px;*/
/*    float: left;*/
/*    border-radius: 5px;*/
/*    width:50%;*/
/*    margin:5px;*/
/*}*/
/*.msgInside span {*/
/*    text-align: right;*/
/*    float:right;*/
/*}*/
/*.dateInside {*/
/*    display:none;*/
/*}*/
/*.timeInside {*/
/*    font-size:0.75em;*/
/*}*/
/*.senderInside {*/
/*    font-size:0.75em;*/
/*    text-align:left;*/
/*    float:left;*/
/*}*/