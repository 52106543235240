body {
    padding: 5%;
    background-color: #F5F5F5;
}

.container {
    padding:0;
    background-color: #FFF;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    height: 550px;
    position:fixed;
    left:0;
    top:1px;
    width:100%;

}

/* ===== MENU ===== */


.menu .items {
    list-style:none;
    margin: auto;
    padding: 0;
}

.menu .items .item {
    height: 70px;
    border-bottom: 1px solid #6780cc;
    display:flex;
    justify-content: center;
    align-items: center;
    color: #9fb5ef;
    font-size: 17pt;
}

.menu .items .item-active {
    background-color:#5172c3;
    color: #FFF;
}

.menu .items .item:hover {
    cursor: pointer;
    background-color: #4f6ebd;
    color: #cfe5ff;
}

/* === CONVERSATIONS === */

.discussions {

    height: 105vh;
    box-shadow: 0px 8px 10px rgb(0 0 0 / 20%);
    overflow: scroll;
    background-color: #87a3ec;
    display: inline-block;
}

.discussions .discussion {
    width: 100%;
    height: 90px;
    background-color: #FAFAFA;
    /*border-bottom: solid 1px #E0E0E0;*/
    display: flex;
    align-items: stretch;
    cursor: pointer;
    justify-content: flex-start;
    flex-direction: column;
}
#archivebn {
    background-color: #04AA6D;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
}

.discussions .search {
    display:flex;
    align-items: center;
    justify-content: center;
    color: #E0E0E0;
}

.discussions .search {
    height: 40px;
    background-color: transparent;
    PADDING:10PX;
    width: 100%;
    display:flex;
    align-items: center;
    cursor: pointer;
}

.discussions .search .searchbar input {
    margin-left: 15px;
    height:38px;
    width:100%;
    border:none;
    font-family: 'Montserrat', sans-serif;;
}

.discussions .search .searchbar *::-webkit-input-placeholder {
    color: #E0E0E0;
}
.discussions .search .searchbar input *:-moz-placeholder {
    color: #E0E0E0;
}
.discussions .search .searchbar input *::-moz-placeholder {
    color: #E0E0E0;
}
.discussions .search .searchbar input *:-ms-input-placeholder {
    color: #E0E0E0;
}

.discussions .message-active {
    width: 98.5%;
    height: 90px;
    background-color: transparent;

}

.discussions .discussion .photo {
    margin-left:20px;
    display: block;
    width: 45px;
    height: 45px;
    background: #E6E7ED;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.online {
    position: relative;
    top: 30px;
    left: 35px;
    width: 13px;
    height: 13px;
    background-color: #8BC34A;
    border-radius: 13px;
    border: 3px solid #FAFAFA;
}

.desc-contact {
    height: 43px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.discussions .discussion .name {
    margin: auto;
    font-family:'Montserrat', sans-serif;
    font-size: 11pt;
    color:#515151;
}

.discussions .discussion .message {
    margin: 6px 0 0 20px;
    font-family:'Montserrat', sans-serif;
    font-size: 9pt;
    color:#515151;
}


.timer {
    margin-left: 15%;
    font-family:'Open Sans', sans-serif;
    font-size: 11px;
    padding: 3px 8px;
    color: #BBB;
    background-color: #FFF;
    border: 1px solid #E5E5E5;
    border-radius: 15px;
}

.chat {
    width: calc(65% - 85px);
    float: right;
    margin: 0;
}

.header-chat {
    width: 40vw;
    background-color: #FFF;
    height: 90px;
    text-align: center;
    box-shadow: 0px 3px 2px rgba(0,0,0,0.100);
    display:flex;
    align-items: center;
}

.chat .header-chat .icon {
    margin-left: 30px;
    color:#515151;
    font-size: 14pt;
}

.chat .header-chat .name {
    text-transform: uppercase;
    font-family:'Montserrat', sans-serif;

    color:#515151;
}

.chat .header-chat .right {
    position: absolute;
    right: 40px;
}


.chat .messages-chat .message {
    display:flex;

    margin-bottom: 8px;
}

.chat .messages-chat .message .photo {
    display: block;
    width: 45px;
    height: 45px;
    background: #E6E7ED;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.chat .messages-chat .text {
    margin: 0 0 0 39PX;
    background-color: #f6f6f6;
    padding: 0px 20px 0px 8px;
    border-radius: 12px;
}
.messages-chat {
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 15vh;
    bottom: 16vh;
    max-height: 87vh;
    width: calc(100% - 245px);
    padding-left: 0;
    right: 0;
}

.messages-chat::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 10px;
}

.messages-chat::-webkit-scrollbar
{
    width: 10px;
    background-color: #F5F5F5;
}

.messages-chat::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    background-image: -webkit-gradient(linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(122,153,217)),
    color-stop(0.72, rgb(73,125,189)),
    color-stop(0.86, rgb(28,58,148)));
}

.text-only {
    float: right !important;
    background-color:lightblue !important;
    margin-left: 150px !important;

}

.time {
    font-size: 10px;
    color: #2d2b2b;
    margin-bottom:10px;

}
.time-last {
    display:inline-list-item;
    font-size:15px;
}

.response-time {
    float: right;
    margin-right: 40px !important;
}

.response {
    float: right;
    margin-right: 0px !important;
    margin-left:auto; /* flexbox alignment rule */
}

.response .text {
    background-color: #e3effd !important;
}

.footer-chat {
    width: calc(100% - 245px);
    height: 80px;
    display: flex;
    align-items: flex-start;
    position: absolute;
    z-index:32;
    right: 0;

    bottom: 40px;
    background-color: transparent;
    flex-direction: row-reverse;
    align-content: space-between;
    flex-wrap: wrap;


}

.chat .footer-chat .icon {

    color:#C0C0C0;
    font-size: 14pt;
}

.chat .footer-chat .send {
    color:#fff;
    background-color: #4f6ebd;
    position: absolute;
    right: 50px;
    bottom:0;
    padding: 12px 12px 12px 12px;
    border-radius: 50px;
    font-size: 14pt;
}

.chat .footer-chat .name {
    margin: 0 0 0 20px;
    text-transform: uppercase;
    font-family:'Montserrat', sans-serif;
    font-size: 13pt;
    color:#515151;
}

.chat .footer-chat .right {
    position: absolute;

}

.write-message {
    border:1px solid grey;
    width:60%;
    right:0;
    height: 50px;
    margin-left: 20px;
    padding: 10px;
}

.footer-chat *::-webkit-input-placeholder {
    color: #C0C0C0;
    font-size: 13pt;
}
.footer-chat input *:-moz-placeholder {
    color: #C0C0C0;
    font-size: 13pt;
}
.footer-chat input *::-moz-placeholder {
    color: #C0C0C0;
    font-size: 13pt;
    margin-left:5px;
}
.footer-chat input *:-ms-input-placeholder {
    color: #C0C0C0;
    font-size: 13pt;
}

.clickable {
    cursor: pointer;
}
#room {
    display:none;
}
.mysub {
    background-color:Blue;
    padding: 30px;
    width:5%;
}
#myUL{
    list-style: none;
    padding:0;
    background-color:transparent;
}
.senderInput {
    display: flex;
    align-items: flex-end;
    margin-bottom: 8px;
    justify-content: space-around;
    align-content: flex-end;
    flex-wrap: nowrap;
    flex-direction: column;
}
.hiddenSideBar {
    width:0%;
    height: 100%;
    display: none;
    background-color:black;
    color:white;
    transition:1s;
}
.showSideBar {
    width: 100%;
    background-color: black;
    height: 100%;
    position: fixed;
    z-index: 100;
    /* display: none; */
    top: 0;
}
.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    text-align:center;

}

.sidenav p ,a{
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;

}

.sidenav a:hover{
    color: #f1f1f1;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

@media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
}
.botChange {
    font-size:15px;
    color:darkgrey;
}
.disNone {
    display:none;
}
#userSelector {
    background-color: #282c34;
    margin:2px 0px;
}
@layer hey {
    .existingUser {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin:0;
        display: inline;
    }
}

.message {
    transform:translateX(100px);
    opacity:0;
    transition: 0.5s;
}
.message.show {
     transform: translateX(0);
     opacity:1;
 }